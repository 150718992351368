
.btn-login{
  background-color: #f9ac00;
  color: black;
}
.btn-login:hover{
  background-color: #f9ac00;
  opacity: 0.8;
  color: black;
}

.mgr5{
  margin-right: 5;
}

.bgred{
  background-color: red;
}